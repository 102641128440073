.resultPopup{
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.5);
    opacity: 0;
    transition: 0.2s;
    pointer-events: none;
}

.resultPopup.shown{
    opacity: 1;
    transition: 0.2s;
    pointer-events: all;
}

.container{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% - 40px);
    max-width: 600px;
    min-height: 200px;
    background: var(--color-primary);
    border: 1px solid var(--color-secondary);
    filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.3));
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px;
}

.container img{
    width: auto;
}