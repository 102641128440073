.container{
    width: 100%;
    margin-bottom: 20px;
}

.textarea{
    width: 100%;
    outline: 0 !important;
    background: none;
    border: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    font-weight: 100;
    line-height: 30px;
    letter-spacing: 1px;
    resize: none
}
.textarea::placeholder{
    color: var(--color-text-primary);
}