.button{
    width: 100%;
    height: 40px;
    border: none;
    background: var(--color-accent);
    color: var(--color-accent-white);
    cursor: pointer;
    transition: 0.2s;
}
.button:hover{
    filter: brightness(110%);
    transition: 0.2s;
}